import { baseDomain } from '@/common/config'
import { service } from '@/utils/request.js'
import axios from 'axios'

const Api = {
  File_Data: baseDomain + '/education/', // 文件详情
  File_Delete: baseDomain + '/education/', // 文件详情
}

export const reqFileData = (params) => {
  return service({
    url: Api.File_Data + params.id,
    method: 'GET',
  })
}

export const reqFileDelete = (params) => {
  return service({
    url: Api.File_Delete + params.id,
    method: 'POST',
  })
}

export const reqFile = (url) => {
  return axios({
    url,
    method: 'GET',
    responseType: 'blob',
  })
}

export const reqReportDownload = (params) => {
  return axios({
    url: Api.File_Delete + params.fileName,
    method: 'GET',
  })
}